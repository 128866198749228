@font-face {
  font-family: "Cyborg";
  src: local("Cyborg"),
  url("./assets/fonts/Cyborg.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "ExoSpace";
  src: local("ExoSpace"),
  url("./assets/fonts/ExoSpace.ttf") format("truetype");
  font-display: swap;
}

html, body {
  width: 100%;
  height: 100%;
  // cursor: url('./../src/assets/images/arrowPoint.svg'), auto;
  cursor: url('./../src/assets/images/arrowPoint.svg') 15 15, move;
  @media (min-width: 760px) and (max-width: 1024px) {
    max-width: 100%;
    max-height: 100%;
  }
}

a[href], input[type='submit'], input[type='checkbox'], input[type='image'], label[for], select, button {
  cursor: url('./../src/assets/images/arrowClick.svg') 15 15, move !important;
}

body {
  margin: 0;
  padding: 0;
  background: #050810;
  color: #F5F5F5;
  box-sizing: border-box;
  font-family: 'ExoSpace', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: #14E956;
  color: #f5f5f5;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3.5rem;
  line-height: 60px;
  // @media only screen and (max-width: 600px) { font-size: 2.8rem; }
}

h2 {
  font-size: 2.8rem;
  line-height: 48px;
  // @media only screen and (max-width: 600px) { font-size: 2.4rem; }
}

h3 {
  font-size: 2.3rem;
  line-height: 34px;
  // @media only screen and (max-width: 600px) { font-size: 1.9rem; }
}

h4 {
  font-size: 2rem;
  line-height: 30px;
  // @media only screen and (max-width: 600px) { font-size: 1.4rem; }
}

a {
  text-decoration: none;
}

// * {
//   outline: solid #f00 1px !important;
// }

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #14e956;
  transform: translate3d(0, 0, 0);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 13px;
}

*::-webkit-scrollbar-track {
  background: #e8fdee;
}

*::-webkit-scrollbar-thumb {
  background-color: #14e956;
  border-radius: 20px;
  border: 2px solid #fdfdfd;
}

.css-7p5u54 {
  height: 20px !important;
  background-color: #e8fdee !important;
  border-radius: 5px !important;
  @media (min-width: 480px) and (max-width: 759px) {
    height: 14px !important;
  }
  @media (max-width: 479px) {
    height: 12px !important;
  }
}

.css-t752vm {
  background-color: #14e956 !important;
}

.css-16e5s8c {
  margin-top: 2rem !important;
  // font-family: "Cyborg" !important;
  font-family: "ExoSpace" !important;
  font-weight: 900 !important;
  font-size: 3rem !important;
  line-height: 1.43;
  @media (min-width: 480px) and (max-width: 759px) {
    font-size: 2.5rem !important;
    line-height: 1.15 !important;
  }
  @media (max-width: 479px) {
    font-size: 2rem !important;
    line-height: 1 !important;
  }
}
